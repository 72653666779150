<script setup lang="ts">
    const props = defineProps(['content']);

    import { Milkdown, useEditor } from '@milkdown/vue';
    import { Crepe, CrepeFeature } from '@milkdown/crepe';
    import "@milkdown/crepe/theme/common/style.css";

    // We have some themes for you to choose
    import "@milkdown/crepe/theme/frame.css";

    useEditor((root) => {
        return new Crepe({
            features: {
                [CrepeFeature.CodeMirror]: false,
            },
            root,
            defaultValue: props.content,
        });
    })
</script>

<template>
    <Milkdown />
</template>