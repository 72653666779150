<template>
    <ClientOnly>
        <MilkdownProvider>
            <MilkDownEditor v-model:content="post.content" />
        </MilkdownProvider>
        <!--
        <form class="w-full bg-white p-6 xs:rounded-xl self-start flex flex-col gap-4" @submit="submitHandler">
            <h1 class="m-0">{{ isNew ? 'Создание' : 'Редактирование' }} поста</h1>
            <input class="rounded-lg border p-3 px-4 w-full" name="title" placeholder="Заголовок" required v-model="post.title">
            <div class="border-[0.5px] rounded-lg overflow-hidden max-h-[50vh]">
                <post-QuillEditorWrapper class="-m-1" v-model:content="post.text" />
            </div>
            <div class="flex gap-2">
                <button class="button bg-green-600 text-white">Сохранить</button>
                <button class="button text-green-600" type="reset" @click="cancelHandler">Отмена</button>
            </div>
        </form>
        -->
    </ClientOnly>
</template>

<script setup>
    import { useGetPostQuery, useInsertPostMutation, useUpdatePostMutation } from '@/composables/urql';
    import MilkDownEditor from '~/components/MilkDownEditor.vue';
    import { MilkdownProvider } from '@milkdown/vue';

    const { params } = useRoute();
    const isCreation = params.id == 'new';
    const { data } = isCreation ? {} : await useGetPostQuery({ variables: params });

    const post = reactive(data?.value?.uogorod_posts_by_pk || { title: '', text: '', content: '' } );
    console.log(data?.value?.uogorod_posts_by_pk)
    const { executeMutation: insertPost } = useInsertPostMutation();
    const { executeMutation: updatePost } = useUpdatePostMutation();

    const submitHandler = async (e) => {
        e.preventDefault();

        if (!post.text) {
            return alert('Заполните содержимое поста');
        }
        
        if (isCreation) {
            const { data } = await insertPost(post);
            post.id = data.insert_uogorod_posts_one.id;
        } else {
            await updatePost({ id: params.id, title: post.title, text: post.text }); 
        }

        navigateTo({ path: `/feed/${post.id}`, replace: true });
    }
</script>